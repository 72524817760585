@tailwind base;

/* width */
*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
    @apply bg-gray-100 dark:bg-zinc-600/50;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    @apply bg-blue-400 dark:bg-blue-400/60;
    /* background: #888; */
    border-radius: 5px;
    transition: all 0.3s;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    @apply bg-blue-600 dark:bg-zinc-300/60;
}

@tailwind components;
@tailwind utilities;

// Markdown styles
@import "./markdown.scss";

@layer base {
    :root {
        --radix-popover-trigger-width: auto;
        --radix-popover-content-available-height: auto;
        --chart-1: 12 76% 61%;
        --chart-2: 173 58% 39%;
        --chart-3: 220, 13%, 85%;
        --chart-4: 43 74% 66%;
        --chart-5: 27 87% 67%;
    }

    .bg-login {
        background: url("/images/fulfillment-min.webp") no-repeat center center fixed;
        background-size: cover;
    }

    .dark {
        --chart-1: 220 70% 50%;
        --chart-2: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-5: 340 75% 55%;
    }
}

@layer components {
    .page-control {
        &.button {
            @apply bg-blue-600 text-white rounded-md px-4 py-2 text-xs font-semibold;
        }
    }

    .driver-popover.popover-tour {
        @apply bg-white text-slate-700 shadow-lg p-4 border-2 border-blue-600 rounded-xl;
        & .driver-popover-navigation-btns {
            @apply flex justify-between;
            button {
                @apply rounded-sm text-sm font-semibold;
            }

            button + button {
                @apply bg-blue-600 text-white rounded-sm text-sm font-semibold border border-transparent;
                text-shadow: none;
            }
        }
    }

    /* react-cloudinary-upload-widget: fix overlay styles*/
    iframe[title*="Upload Widget"] {
        pointer-events: all;
    }
}

@layer utilities {
    .quickview {
        @apply px-0;

        @apply rounded-xl sm:max-w-[90%] md:w-[400px] w-[400px] max-w-[400px] py-9 h-[calc(100%_-_2rem)] m-4;

        &.sm {
            @apply rounded-xl sm:max-w-[90%] md:max-w-[50%] md:w-[500px] w-[500px] max-w-[500px] py-9 h-[calc(100%_-_2rem)] m-4;
        }

        &.md {
            @apply rounded-xl sm:max-w-[90%] md:max-w-[70%] w-[650px] max-w-[650px] py-9 h-[calc(100%_-_2rem)] m-4;
        }

        &.lg {
            @apply rounded-xl sm:max-w-[90%] md:max-w-[80%] w-[800px] max-w-[800px] py-9 h-[calc(100%_-_2rem)] m-4;
        }

        &.xl {
            @apply rounded-xl sm:max-w-[90%] md:max-w-[90%] w-[950px] max-w-[950px] py-9 h-[calc(100%_-_2rem)] m-4;
        }

        &.xxl {
            @apply rounded-xl w-[90%] max-w-[90%] lg:w-[1100px] lg:max-w-[90%] xl:w-[1100px] xl:max-w-[1100px] py-9 h-[calc(100%_-_2rem)] m-4;
        }

        &.full {
            @apply rounded-xl sm:max-w-[90%] md:max-w-[calc(100%_-_2rem)] w-[calc(100%_-_2rem)] max-w-[calc(100%_-_2rem)] py-9 h-[calc(100%_-_2rem)] m-4;
        }
    }

    .transition-background {
        transition: background-image 1s ease-in-out;
    }

    .SelectorContent {
        width: var(--radix-popover-trigger-width);
        /*max-height: var(--radix-popover-content-available-height);*/
    }

    .PopoverContent {
        min-width: var(--radix-popover-trigger-width);
        max-width: fit-content;
        max-height: var(--radix-popover-content-available-height);
    }

    .input {
        @apply focus-visible:ring-0 focus-visible:shadow-sm dark:focus-visible:shadow-none focus-visible:shadow-blue-200 focus-visible:border-blue-200 text-xs;
        @apply flex h-9 w-full rounded-md border border-slate-200 bg-white px-3 py-1 shadow-sm;
        @apply transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none;
        @apply dark:border-white/5 dark:bg-white/5 dark:placeholder:text-zinc-500 dark:focus-visible:border-white/10 dark:focus-visible:bg-white/10 dark:text-zinc-300;

        /* Sizes */
        &.sm {
            @apply h-7 text-xs rounded-sm;
        }
    }

    .textarea {
        @apply focus-visible:!ring-0 focus-visible:shadow-sm focus-visible:shadow-blue-200 focus-visible:border-blue-200 text-xs bg-white;
        @apply flex h-9 w-full rounded-md border border-slate-200 bg-transparent px-3 py-1;
        @apply shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm;
        @apply file:font-medium placeholder:text-slate-500 focus-visible:outline-none;
    }

    .button {
        @apply rounded;

        &.primary {
            @apply bg-blue-600 text-white hover:bg-blue-700 hover:text-white;
            @apply dark:bg-blue-500 dark:text-white dark:hover:bg-blue-600 dark:hover:text-white;
        }

        &.secondary {
            @apply bg-slate-400 text-white hover:bg-slate-500;
            @apply dark:bg-zinc-600 dark:text-zinc-300 dark:hover:bg-zinc-700 dark:hover:text-zinc-300;
        }

        &.danger {
            @apply bg-red-700 text-white hover:bg-red-800 hover:text-white;
            @apply dark:bg-red-600/60 dark:text-white dark:hover:bg-red-700 dark:hover:text-white;
        }

        &.success {
            @apply bg-green-600 text-white hover:bg-green-700 hover:text-white;
        }

        &.warning {
            @apply bg-orange-500 text-white hover:bg-orange-600 hover:text-white;
        }

        &.info {
            @apply bg-sky-400 text-white hover:bg-sky-500;
        }

        &.cancel {
            @apply bg-white text-slate-600 hover:bg-gray-50;
        }

        &.outlined {
            @apply bg-transparent border hover:bg-slate-100 text-slate-600;
        }

        /* Sizes */
        &.sm {
            @apply h-7 text-xs rounded;
        }

        &.xs {
            @apply h-5 text-xs rounded px-2;
        }
    }

    .label {
        @apply text-xs uppercase text-slate-500;
    }

    .bottom-indicator[data-state="active"] {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid transparent;
            @apply border-b-zinc-300 dark:border-b-zinc-600;
        }
    }
}
