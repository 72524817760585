/* Estilos para contenido Markdown */
.markdown-content {
  @apply text-sm leading-relaxed;

  h1 {
    @apply text-2xl font-bold mb-4 mt-6 text-slate-900 dark:text-zinc-100 border-b pb-2 border-slate-200 dark:border-zinc-700;
  }

  h2 {
    @apply text-xl font-bold mb-3 mt-5 text-slate-800 dark:text-zinc-200;
  }

  h3 {
    @apply text-lg font-semibold mb-3 mt-4 text-slate-800 dark:text-zinc-300;
  }

  p {
    @apply mb-4 text-slate-700 dark:text-zinc-300;
  }

  a {
    @apply text-blue-600 dark:text-blue-400 hover:underline;
  }

  blockquote {
    @apply border-l-4 border-slate-300 dark:border-zinc-600 pl-4 italic text-slate-600 dark:text-zinc-400 my-4;
  }

  ul {
    @apply pl-8 mb-4 list-disc text-slate-700 dark:text-zinc-300;
  }

  ol {
    @apply pl-8 mb-4 list-decimal text-slate-700 dark:text-zinc-300;
  }

  li {
    @apply mb-1;
  }

  hr {
    @apply my-6 border-slate-200 dark:border-zinc-700;
  }

  img {
    @apply max-w-full h-auto rounded-md my-4;
  }

  code {
    @apply bg-slate-100 dark:bg-zinc-800 px-1 py-0.5 rounded text-sm font-mono text-slate-800 dark:text-zinc-300;
  }

  pre {
    @apply bg-slate-100 dark:bg-zinc-800 p-4 rounded-md overflow-x-auto mb-4;
    
    code {
      @apply bg-transparent p-0 text-slate-800 dark:text-zinc-300;
    }
  }

  table {
    @apply w-full border-collapse mb-4;
    
    th {
      @apply bg-slate-100 dark:bg-zinc-800 text-left p-2 border border-slate-300 dark:border-zinc-700;
    }
    
    td {
      @apply p-2 border border-slate-200 dark:border-zinc-700;
    }
  }
} 